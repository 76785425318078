var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Thesaurus")]),
          _c("gov-body", [
            _vm._v(
              "\n      On this page, you can view and edit the thesaurus used when a user makes\n      a keyword search. The thesaurus means that when users enter in a search\n      term - if it appears in the thesaurus, all synonyms will also be\n      searched.\n    "
            )
          ]),
          _vm.loading
            ? _c("ck-loader")
            : _c("gov-button", { on: { click: _vm.onExportCsv } }, [
                _vm._v("Download thesaurus")
              ]),
          _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Synonyms")]),
          _vm.loading
            ? _c("ck-loader")
            : _c("ck-thesaurus-list", { attrs: { synonyms: _vm.synonyms } })
        ],
        1
      ),
      _vm.auth.isGlobalAdmin
        ? _c(
            "gov-grid-column",
            { attrs: { width: "one-third" } },
            [
              _c(
                "gov-button",
                { attrs: { to: { name: "thesaurus-edit" }, expand: "" } },
                [_vm._v("Edit thesaurus")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }